body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "PT Serif", serif;
  }

  
.text-center{
    text-align:center;
}

.box-list-member{
    display:flex;
    flex-direction: row;
}
.list-member-text{
    margin-left:2em;
}
.App {
    margin-top: 15px;
  }
  
  .App .navbar-brand {
    font-weight: bold;
  }
  
.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }

.flight-deal-card{
    border: 1px solid black;
    word-wrap: break-word;
    width: 75%;
    padding: .5em 1.5em .5em 1.5em;
    margin-bottom: 1em;
}

.flight-deal-card h3{
    font-size: 1.25em;
    color: black;
}

.flight-deal-card h3 a{
    color:black;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
